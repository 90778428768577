import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import useValidationWithWarnings from '@rsa-digital/evo-shared-components/helpers/forms/useValidationWithWarnings';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import FormFooter from 'components/FormFooter';
import AboutYouForm from 'forms/AboutYouForm';
import useAddressRules from 'forms/AboutYouForm/AddressForm/validation';
import useAboutYouRules from 'forms/AboutYouForm/validation';
import AboutYourPetsForm from 'forms/AboutYourPetsForm';
import useAboutYourPetRules from 'forms/AboutYourPetsForm/AboutYourPet/validation';
import AboutYourPolicyForm from 'forms/AboutYourPolicyForm';
import BannerWithAddButton from 'forms/BannerWithAddButton';
import JointPolicyholderForm from 'forms/JointPolicyholderForm';
import useJointPolicyholderRules from 'forms/JointPolicyholderForm/validation';
import KeepingYouInformedForm from 'forms/KeepingYouInformedForm';
import useKeepingYouInformedRules from 'forms/KeepingYouInformedForm/validation';
import SectionHeading from 'forms/SectionHeading';
import { ProductId } from 'helpers/businessConstants';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { areDateValuesEqual } from 'helpers/dateHelpers';
import { PageTitle, trackFieldError, trackTextButtonClick } from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { useJointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { usePetsDetails } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import { initialDateValue } from 'state/formData/shared/dateValue';
import { CsAdditionalFormSectionBanner } from 'types/contentStack';
import { FormDetails } from './types';
import { useDisplayAssumptionWarningsOnRender } from './useDisplayAssumptionWarningsOnRender';

type AboutYouAndYourPetFormData = {
  csPetAboutYouAndYourPet: {
    joint_policyholder_banner: CsAdditionalFormSectionBanner;
    about_you_section_heading: string;
    joint_policyholder_section_heading: string;
    keeping_you_informed_section_heading: string;
    next_button_text: string;
  };
};

export const query = graphql`
  query {
    csPetAboutYouAndYourPet {
      joint_policyholder_banner {
        heading_text
        body_text
        button_text
        button_aria_label
        icon {
          icon_code
        }
      }
      next_button_text
      about_you_section_heading
      joint_policyholder_section_heading
      keeping_you_informed_section_heading
    }
  }
`;

type AboutYouAndYourPetFormProps = {
  moveNext: () => void;
};

const AboutYouAndYourPetForm: React.FC<AboutYouAndYourPetFormProps> = ({ moveNext }) => {
  const {
    csPetAboutYouAndYourPet: {
      joint_policyholder_banner,
      about_you_section_heading,
      joint_policyholder_section_heading,
      keeping_you_informed_section_heading,
      next_button_text,
    },
  } = useStaticQuery<AboutYouAndYourPetFormData>(query);

  const aboutYourPetSectionId = 'about-your-pet-section';
  const aboutYouSectionId = 'about-you-section';
  const jointPolicyholderSectionId = 'joint-policyholder-section';
  const aboutYourPolicySectionId = 'about-your-policy-section';
  const keepingYouInformedSectionId = 'keeping-you-informed-section';

  const quote = useCurrentQuote();
  const isAggsQuote = quote.productId !== ProductId.DIRECT;

  const [petDetails] = usePetsDetails();
  const petRules = useAboutYourPetRules();

  const showAboutSection: boolean = petDetails.some(
    ({ petType, petName, petIsEligible, petInGoodHealth, petLivesWithYou }) =>
      (petType === petType_DOG && petInGoodHealth && petIsEligible && petName) ||
      (petType === petType_CAT && petInGoodHealth && petLivesWithYou && petName)
  );

  const [customerDetails] = useCustomerDetails();
  const customerRules = useAboutYouRules();
  const addressRules = useAddressRules();

  const [
    jointPolicyholderDetails,
    updateJointPolicyholderDetails,
  ] = useJointPolicyholderDetails();
  const jointPolicyHolderRules = useJointPolicyholderRules();

  const [displayJointPolicyholderForm, setDisplayJointPolicyholderForm] = useState(
    jointPolicyholderDetails.includeJointPolicyholder
  );

  // warning panel is displayed when the customer enters their own details in the joint policyholder form
  const displayJointPolicyholderWarningPanel =
    customerDetails.customerFirstName !== '' &&
    customerDetails.customerFirstName ===
      jointPolicyholderDetails.jointPolicyholderFirstName &&
    customerDetails.customerLastName !== '' &&
    customerDetails.customerLastName ===
      jointPolicyholderDetails.jointPolicyholderLastName &&
    !areDateValuesEqual(customerDetails.customerDob, initialDateValue) &&
    areDateValuesEqual(
      customerDetails.customerDob,
      jointPolicyholderDetails.jointPolicyholderDob
    );

  const [policyDetails] = usePolicyDetails();

  const keepingYouInformedRules = useKeepingYouInformedRules();

  const formErrorRules = {
    ...petRules.errors,
    ...customerRules.errors,
    ...addressRules,
    ...(jointPolicyholderDetails.includeJointPolicyholder ? jointPolicyHolderRules : {}),
    ...(!isAggsQuote ? keepingYouInformedRules : {}),
  };

  const formWarningRules = {
    ...petRules.warnings,
    ...customerRules.warnings,
  };

  const formDetails: FormDetails = {
    petDetails,
    ...customerDetails,
    ...jointPolicyholderDetails,
    ...policyDetails,
  };

  const {
    getError,
    getWarning,
    showValidation,
    resetValidation,
    validateOnSubmit,
  } = useValidationWithWarnings(
    formDetails,
    formErrorRules,
    formWarningRules,
    trackFieldError
  );

  useDisplayAssumptionWarningsOnRender(showValidation);
  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      <section id={aboutYourPetSectionId} aria-label="Your pet details">
        <AboutYourPetsForm
          formValidation={{ getError, getWarning, showValidation, resetValidation }}
        />
      </section>
      {showAboutSection && (
        <Grid alignLeft>
          <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
            <section aria-labelledby={aboutYouSectionId}>
              <SectionHeading
                heading={about_you_section_heading}
                id={aboutYouSectionId}
              />
              <AboutYouForm formValidation={{ getError, getWarning, showValidation }} />
            </section>
          </GridItem>
        </Grid>
      )}
      {displayJointPolicyholderForm ? (
        <Grid alignLeft>
          <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
            <section aria-labelledby={jointPolicyholderSectionId}>
              <SectionHeading
                heading={joint_policyholder_section_heading}
                id={jointPolicyholderSectionId}
              />
              <JointPolicyholderForm
                removeJointPolicyholderButtonOnClick={() =>
                  setDisplayJointPolicyholderForm(false)
                }
                formValidation={{ getError, showValidation }}
                displayWarningPanel={displayJointPolicyholderWarningPanel}
              />
            </section>
          </GridItem>
        </Grid>
      ) : (
        <>
          {showAboutSection && (
            <BannerWithAddButton
              data-cy="addJointPolicyholderBanner"
              pageTitle={PageTitle.AboutYouAndYourPet}
              headingText={joint_policyholder_banner.heading_text}
              bodyText={joint_policyholder_banner.body_text}
              buttonText={joint_policyholder_banner.button_text}
              buttonAriaLabel={joint_policyholder_banner.button_aria_label}
              buttonId="addJointPolicyholderButton"
              icon={unwrapSingleton(joint_policyholder_banner.icon)?.icon_code}
              gridItemProps={{
                desktop: 6,
                tabletLandscape: 6,
                tabletPortrait: 6,
              }}
              addFormSectionButtonClick={() => {
                updateJointPolicyholderDetails({ includeJointPolicyholder: true });
                setDisplayJointPolicyholderForm(true);
                trackTextButtonClick(
                  PageTitle.AboutYouAndYourPet,
                  'Add joint policy holder'
                );
              }}
            />
          )}
        </>
      )}
      <Grid alignLeft>
        <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
          {showAboutSection && (
            <section aria-labelledby={aboutYourPolicySectionId}>
              <AboutYourPolicyForm />
            </section>
          )}
          {!isAggsQuote && showAboutSection && (
            <section aria-labelledby={keepingYouInformedSectionId}>
              <SectionHeading
                heading={keeping_you_informed_section_heading}
                id={keepingYouInformedSectionId}
              />
              <KeepingYouInformedForm formValidation={{ getError, showValidation }} />
            </section>
          )}
          <FormFooter
            moveNextButton={{
              text: next_button_text,
              onClick: () =>
                trackTextButtonClick(PageTitle.AboutYouAndYourPet, 'Submit details'),
            }}
            pageTitle={PageTitle.AboutYouAndYourPet}
          />
        </GridItem>
      </Grid>
    </form>
  );
};

export default AboutYouAndYourPetForm;
