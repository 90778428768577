import { graphql, useStaticQuery } from 'gatsby';
import { PageTitle } from 'helpers/eventTracking';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion, CsQuestionWithPlaceholder } from 'types/contentStack';
import {
  Question,
  QuestionWithOptionalPlaceholder,
  QuestionWithPlaceholder,
} from 'types/forms';

export type JointPolicyholderQuestions = {
  jointPolicyholderFirstName: QuestionWithOptionalPlaceholder;
  jointPolicyholderLastName: QuestionWithOptionalPlaceholder;
  jointPolicyholderDob: Question;
  jointPolicyholderTitle: QuestionWithPlaceholder;
};

type csPetJointPolicyholderQuestions = {
  csPetJointPolicyholderQuestions: {
    first_name: CsQuestionWithPlaceholder;
    last_name: CsQuestionWithPlaceholder;
    date_of_birth: CsQuestion;
    joint_policyholder_title: CsQuestionWithPlaceholder;
  };
};

const query = graphql`
  query {
    csPetJointPolicyholderQuestions {
      joint_policyholder_title {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      first_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      last_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      date_of_birth {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
  }
`;

const useJointPolicyholderQuestions = (): JointPolicyholderQuestions => {
  const csQuestions = useStaticQuery<csPetJointPolicyholderQuestions>(query);
  const {
    processQuestion,
    processQuestionWithPlaceholder,
    processQuestionWithOptionalPlaceholder,
  } = useQuestionProcessor(PageTitle.AboutYouAndYourPet);

  return {
    jointPolicyholderTitle: processQuestionWithPlaceholder(
      csQuestions.csPetJointPolicyholderQuestions.joint_policyholder_title
    ),
    jointPolicyholderFirstName: processQuestionWithOptionalPlaceholder(
      csQuestions.csPetJointPolicyholderQuestions.first_name
    ),
    jointPolicyholderLastName: processQuestionWithOptionalPlaceholder(
      csQuestions.csPetJointPolicyholderQuestions.last_name
    ),
    jointPolicyholderDob: processQuestion(
      csQuestions.csPetJointPolicyholderQuestions.date_of_birth
    ),
  };
};

export default useJointPolicyholderQuestions;
