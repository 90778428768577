import {
  dateToDateValue,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { useFirstEligibleStartDateFromToday } from 'businessLogic/petAge';
import React, { useEffect, useReducer, useState } from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import SectionHeading from 'forms/SectionHeading';
import { areDateValuesEqual, formatLongDateWithOrdinalSuffix } from 'helpers/dateHelpers';
import { PageTitle, trackLinkClick } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { combinePetNamesWithAnd } from 'helpers/placeholders/quotePlaceholders';
import { usePetsDetails } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import CoverStartDateQuestion from './CoverStartDateQuestion';
import { PanelWithActionLinkWithMargins } from './CoverStartDateQuestion/styles';
import useAboutYourPolicyQuestions from './questions';

const AboutYourPolicyForm: React.FC = () => {
  const questions = useAboutYourPolicyQuestions();

  const [minDate, isMinDateInFuture] = useFirstEligibleStartDateFromToday();
  const [petsDetails] = usePetsDetails();

  // If the any of the Pets are too young at the current date, we restrict the date picker
  // to ensure they are valid at the cover start date and display a special message.
  const [displayYoungPetMessage, setDisplayYoungPetMessage] = useState(false);
  const [showCalendar, toggleCalendar] = useReducer((state) => !state, false);
  const petNames = petsDetails.map((pet) => pet.petName);
  const combinedPetNames = combinePetNamesWithAnd(petNames ?? []);
  const [policyDetails] = usePolicyDetails();

  useEffect(() => {
    setDisplayYoungPetMessage(isMinDateInFuture);
  }, [isMinDateInFuture]);
  const coverStartDateUtc = new Date(policyDetails.coverStartDate);

  const formattedCoverStartDate = formatLongDateWithOrdinalSuffix(
    coverStartDateUtc,
    false
  );
  const explanatoryText = displayYoungPetMessage
    ? questions.coverStartDate.youngPetExplanatoryText
    : questions.coverStartDate.explanatoryText;

  const aboutYourPolicySectionId = 'about-your-policy-section';
  const isStartDateToday = areDateValuesEqual(
    dateToDateValue(new Date()),
    dateToDateValue(parseDate(policyDetails.coverStartDate))
  );

  const startDateText = isStartDateToday
    ? petNameReplacer(
        combinedPetNames,
        questions.coverStartDate.yourCoverWillStartTodayLabel
      )
    : petNameReplacer(combinedPetNames, questions.coverStartDate.yourCoverWillStartLabel);

  return (
    <>
      <SectionHeading
        heading={questions.coverStartDate.questionText}
        id={aboutYourPolicySectionId}
      />

      {explanatoryText && (
        <RichTextWithModal html={explanatoryText} pageTitle={PageTitle.CoverStartDate} />
      )}
      <PanelWithActionLinkWithMargins
        id="cover-start-panel"
        summaryText={startDateText}
        heading={formattedCoverStartDate}
        link={{
          onClick: () => {
            toggleCalendar();
            trackLinkClick(
              PageTitle.CoverStartDate,
              showCalendar
                ? questions.coverStartDate.hideCalendar
                : questions.coverStartDate.chooseDifferentStartDateText
            );
          },
          text: showCalendar
            ? questions.coverStartDate.hideCalendar
            : questions.coverStartDate.chooseDifferentStartDateText,
          screenreaderText: showCalendar
            ? questions.coverStartDate.hideCalendar
            : questions.coverStartDate.chooseDifferentStartDateText,
        }}
      />
      {showCalendar && (
        <CoverStartDateQuestion
          minDate={minDate}
          onChangeCallback={() => setDisplayYoungPetMessage(false)}
        />
      )}
    </>
  );
};

export default AboutYourPolicyForm;
